<template>
  <div class="lance-manual-keyboard">
    <div class="lbtn"><u-btn label="Lançar manualmente" no-caps @click="digitarLance" v-shortkey="['f2']"
                             @shortkey.native="digitarLance"  /> <span>F2</span></div>

    <!-- DIALOG -->
    <u-dialog
        v-model="digitarLanceDialog"
        @cancel="digitarLanceCancel"
        @ok="digitarLanceConfirm"
    >
      <span slot="title">Digitar Lance</span>

      <!--<span slot="message">What is your superhero of choice?</span>-->

      <div slot="body">
        <u-field
            icon="attach_money"
            label="Lance Manual:"
            :label-width="3"
        >
          <u-input v-if="!preventLance" ref="incrementoInput" autofocus v-model="digitarLanceValor" @keydown.prevent.enter="digitarLanceConfirm" class="no-shortkey" v-money="money" />
        </u-field>
        <u-field
            class="m-t"
            icon="number"
            label="Boleta:"
            :label-width="3"
        >
          <u-input ref="boletaInput" v-model="boleta" @keydown.prevent.enter="digitarLanceConfirm" class="no-shortkey" />
        </u-field>
      </div>

      <template slot="buttons" slot-scope="props">
        <u-btn flat label="Cancelar" @click="props.cancel" />
        <u-btn color="primary" label="Confirmar alteração" @click="props.ok" />
      </template>
    </u-dialog>
  </div>
</template>

<script>
import {UDialog, UField, UInput} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'

export default {
  name: 'LanceManual',
  components: {UDialog, UInput, UField},
  directives: {money: VMoney},
  data () {
    return {
      preventLance: false,
      money: REAL_BRL,
      digitarLanceDialog: false,
      digitarLanceValor: null,
      boleta: null
    }
  },
  methods: {
    digitarLance () {
      // this.resetAlteracaoLance()
      this.digitarLanceDialog = true
      window.setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.incrementoInput.focus()
        })
      }, 400)
    },
    digitarLanceConfirm () {
      //this.$emit('lance', Number(String(this.digitarLanceValor).replace(/\D/gi, '')))
      try {
        this.$emit('lance', convertRealToMoney(this.digitarLanceValor), this.boleta)
      }catch (e) {}
      this.resetAlteracaoLance()
    },
    digitarLanceCancel () {
      this.resetAlteracaoLance()
    },
    resetAlteracaoLance () {
      this.preventLance = true
      this.$nextTick(() => {
        this.digitarLanceValor = 0
        this.boleta = null
        this.$nextTick(() => {
          this.preventLance = false
          this.digitarLanceDialog = false
        })
      })
    }
  }
}
</script>
