<script>
export default {
  name: 'historico-lances',
  inject: ['controlador'],
  props: ['lote'],
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  destroyed () {
  },
  methods: {},
  components: {}
}
</script>

<template>
  <div class="css-wrapper-100 historico-lances-container">
    <div class="historico-lances sys-table">
      <div class="table-content">
        <div class="header">
          <div class="col-apelido">Apelido</div>
          <div class="col-data">Data</div>
          <div class="col-valor">Valor</div>
        </div>
        <div class="body">
          <div class="item" v-for="lance in lote.lances">
            <div class="col-apelido" style="line-break: anywhere">{{ lance.arrematante.apelido }} <span class="m-l-xs m-r-xs" v-if="lance.boleta">({{lance.boleta}})</span><span v-if="lance.parcelado" class="isParcelado">
              <template v-if="lance.entrada">{{ lance.entrada }}% + {{lance.parcelas}}x</template>
              <template v-else>{{lance.parcelas}}x</template>
              </span>
            </div>
            <div class="col-data">{{ lance.data.date|formatDate }}</div>
            <div class="col-valor">R$ {{ lance.valor | moeda }}</div>
          </div>
        </div>
      </div>
      <div class="lbtn float-lbtn-bottom-left">
        <u-btn label="Apagar todos os lances"
               no-caps
               v-shortkey="['shift','del']" @shortkey="controlador && controlador.$refs.keyboard.deletarLancesLote()"
               @click="controlador && controlador.$refs.keyboard.deletarLancesLote()"
        />
        <span>SHIFT+DEL</span></div>
    </div>
  </div>
</template>
