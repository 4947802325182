<script>
/**
 * TODO: Verificar bug de quando deleta lance depois de mudanças no código, se isto pode acontecer em cenário real, como uma reconexão websocket
 */
import DefaultMixin from '../../mixin'
import Stats from './include/Stats'
import StatsSup from './include/StatsSup'
import {USpinnerUloc} from 'uloc-vue'
import {find} from '../../../../domain/leiloes/services/index'
import LeilaoMixin from 'comunicator/src/integrations/vue/mixins/leilao.mixin.js'
// import LeilaoMixin from '../../../../../comunicator_wrapper/src/integrations/vue/mixins/leilao.mixin.js'
import Keyboard from './include-v2/Keyboard'
import ScreenOptions from './include-v2/ScreenOptions'
import LoteInfo from './include-v2/LoteInfo'
import Status from './include-v2/Status'
import HistoricoLances from './include-v2/HistoricoLances'
import Estatisticas from './include-v2/Estatisticas'
import Monitor from './include-v2/Monitor'
import {STATUS_RETIRADO, STATUS_EM_PREGAO, STATUS_HOMOLOGANDO, STATUS_CANCELADO} from '@/domain/leiloes/helpers/LoteStatus'

export default {
  name: 'ControladorLeilao',
  provide: function () {
    return {
      controlador: this
    }
  },
  inject: ['page'],
  mixins: [DefaultMixin, LeilaoMixin],
  components: {
    Monitor,
    Estatisticas,
    HistoricoLances,
    Status,
    LoteInfo,
    ScreenOptions,
    Keyboard,
    USpinnerUloc,
    StatsSup,
    Stats
  },
  data () {
    return {
      loading: true,
      leilao: this.page.leilao,
      lote: {
        numero: 0,
        status: 1
      },
      visibledKeyboard: true,
      visibledKeyboardStats: false,
      hasNovoLance: false,
      estatisticas: null
    }
  },
  computed: {
    rootClasses () {
      const css = []
      css.push('show-keyboard')
      if (this.loteIsRetirado) {
        css.push('loteRetirado')
      }
      return css
    },
    loteIsRetirado () {
      return this.lote && (this.lote.status === STATUS_RETIRADO || this.lote.status === STATUS_CANCELADO)
    }
  },
  watch: {
    timerPregao (v) {
      if (this.leilao.cronometro !== false && (v === 0 || v === '00:00') && this.lote.status === STATUS_EM_PREGAO && this.leilao.extra && this.leilao.extra.homologarAutomaticamente) {
        this.$timoutHomologar = setTimeout(() => {
          this.lote.status = STATUS_HOMOLOGANDO
          this.$refs.keyboard.updateLoteStatus(STATUS_HOMOLOGANDO, this.lote.id)
        }, 3000)
      } else {
        if (this.$timoutHomologar) {
          clearTimeout(this.$timoutHomologar)
        }
      }
    }
  },
  mounted () {
    this.page.setActive('control')
    this.load()
  },
  destroyed () {
  },
  methods: {
    load () {
      this.loading = true
      find(this.leilao.id)
        .then(({data}) => {
          this.loading = false
          this.leilao = Object.assign({}, this, data)
          if (!data.pregaoAtivo || !data.pregaoAtivo.lote) {
            console.log('Pregão ainda não está ativo')
            return
          }
          this.lote = data.pregaoAtivo.lote
          console.log(data)
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    },
    showKeyboard () {
      // TODO: Clicar e ocultar todos
      this.visibledKeyboard = true
      this.visibledKeyboardStats = false
    },
    showKeyboardStats () {
      this.visibledKeyboard = false
      this.visibledKeyboardStats = true
    },
    updateEstatisticas (e) {
      this.estatisticas = e
    }
  },
  meta: {
    title: 'Controlador Leilão',
    name: 'Monitor Leilão'
  }
}
</script>

<template>
  <div v-if="!loading" class="controlador-v2" :class="rootClasses">
    <stats-sup :leilao="leilao" />

    <div class="container-app">
      <screen-options :active-keyboard="visibledKeyboard" :active-resume="visibledKeyboardStats" @toggleKeyboard="showKeyboard" @toggleKeyboardStats="showKeyboardStats" />
      <stats :leilao="leilao" :lote="lote" hide-status />

      <lote-info
          v-if="!loteIsRetirado"
          @alteraLanceInicial="() => { $refs.keyboard && $refs.keyboard.alteraLanceInicial() }"
          @alteraValorMinimo="$refs.keyboard && $refs.keyboard.alteraValorMinimo()"
          @alteraIncremento="$refs.keyboard && $refs.keyboard.alteraIncremento()"
          :lote="lote"
          :leilao="leilao"
      />

      <div class="column-3">
        <div class="controlador-status-monitor">
          <status :leilao="leilao" :lote="lote" />
          <monitor :leilao="leilao" :lote="lote" />
        </div>
        <div>
          <historico-lances :lote="lote" />
        </div>
      </div>
      <keyboard
          ref="keyboard"
          :leilao="leilao"
          :lote="lote"
          :state="visibledKeyboard"
      />
      <estatisticas
          ref="keyboardStats"
          :leilao="leilao"
          :lote="lote"
          :state="visibledKeyboardStats"
          @estatisticas="updateEstatisticas"
      />
    </div>
  </div>
  <div class="controlador-v2 flex flex-center items-center column h-full no-select text-white"
       style="justify-content: center !important" v-else>
    <div class="m-b-xs">
      <u-spinner-uloc />
    </div>
    Carregando a aplicação
  </div>
</template>

<style src="../../assets/controlador-v2.styl" lang="stylus" />
